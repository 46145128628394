"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_common_1 = require("../core/core-common");
var logging_1 = require("../core/logging");
$(function () {
    (0, logging_1.logScriptLoad)("[direct] [home]");
    (0, core_common_1.setupCommon)();
    if (Number($("#SiteUnavailable").val()) === 1) {
        (0, core_common_1.showSiteMaintenanceDialog)();
    }
    (0, core_common_1.setNavigationStepStates)(0 /* NavStep.Vehicle */);
    var target = '#tabOne';
    var selected = '#tabOneContent';
    var urlParams = new URLSearchParams(window.location.search);
    var qs = urlParams.get('logout');
    if (qs === "1") {
        target = '#tabTwo';
        selected = '#tabTwoContent';
    }
    var tabbedCon = new core_common_1.TabbedContent({
        targetDiv: '#tabbedContent',
        tabSelectedClass: 'current',
        content: ['#tabOneContent', '#tabTwoContent'],
        targetTab: target,
        selectedDiv: selected
    });
});
